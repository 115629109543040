import React, { useCallback, useEffect, useState } from "react"
import getValue from "get-value"
import WarrantyDetails from "./warranty-details"
import { getRegionCurrency } from "@utils/region"
import QuantityInput from "@components/quantity-input"
import { ReactComponent as Close } from "@svgs/close.svg";
import { fetchProductFromVariant } from "../instagram-feed/helpers"
import { getUriFromDocument } from "@src/utils/routing"
import { Link } from "gatsby"
import Image from "../image"
import { getPrice } from "./helpers"
import { trackAddToCart, trackRemoveFromCart, trackSelectItem } from "@src/services/analytics"
import useToggleStates from "@src/hooks/useToggleStates"
import { useCartStore } from "@src/hooks/useStore"

const LineItem = props => {
  const { lineItem, index, setCashbackOpened } = props
  const {
    merchandise,
    cost: { amountPerQuantity, compareAtAmountPerQuantity, totalAmount, subtotalAmount },
    attributes,
    warrantyDetails,
  } = lineItem

  const { removeLineItems, updateLineItems, cart, loading } = useCartStore(["removeLineItems", "updateLineItems", "cart", "loading"]);

  const { toggleAllFunction } = useToggleStates();

  const [sanityProduct, setSanityProduct] = useState();

  useEffect(() => {
    const productId = lineItem?.merchandise?.product?.id?.match(/[^/]+$/)?.[0];

    if (productId) {
      fetchProductFromVariant(productId, false)
        .then((res) => {
          setSanityProduct(res);
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    }
  }, [lineItem]);

  const variantId = lineItem?.merchandise?.id?.match(/[^/]+$/)?.[0];
  const productLink = sanityProduct && sanityProduct?.slug && sanityProduct?.priceFrom != "0.0"
    ? getUriFromDocument(sanityProduct) + `?variantId=${variantId}`
    : null;

  const isPreorder = attributes.find(
    attribute => attribute.key === "_preorder" && attribute.value === "true"
  )
  const isBackorder = attributes.find(
    attribute => attribute.key === "_preorder" && attribute.value === "true"
  )
  const isCashback = attributes.find(
    attribute => attribute.key === "_cashback" && attribute.value === "true"
  );
  const isPreOrBackOrder = isPreorder || isBackorder
  const backOrderText = isPreorder ? "Pre-Order" : "Back-Order"

  const variantImage = getValue(merchandise, "image") ? (
    <div className="relative flex rounded-xl overflow-hidden h-auto my-auto w-[125px] h-[125px]">
      <Image
        width={125}
        height={125}
        image={{
          shopifyURL: getValue(merchandise, "image.src"),
          _type: "shopifyImage",
        }}
        alt={`${lineItem.merchandise.product.title} product shot`}
        title={`${lineItem.merchandise.product.title} product shot`}
        className="object-contain w-full h-full"
      />
    </div>
  ) : (
    <div className="self-auto w-full h-full bg-light-gray rounded-xl"></div>
  )

  const personalizationAttributes = lineItem.attributes
    .filter(attribute => attribute.key.toLowerCase().startsWith("text"))
    .map(attribute => ({ name: attribute.key, value: attribute.value }))

  const selectedOptionsWithPersonalization = [
    ...(merchandise?.selectedOptions ?? []),
    ...personalizationAttributes,
  ]

  const selectedOptions = selectedOptionsWithPersonalization.length
    ? selectedOptionsWithPersonalization
      .filter(({ value }) => value !== "Default Title")
      .map((option, index) => (
        <div key={`${option.name}-${index}`} className="flex flex-wrap gap-1">
          <span className="font-medium">{option.name}:</span>
          <span>{option.value}</span>
        </div>
      ))
    : null

  const selectedOptionsRaw =
    selectedOptionsWithPersonalization.length
      ? selectedOptionsWithPersonalization
        .filter(({ value }) => value !== "Default Title")
        .map((option, index) => {
          return `${option.name}: ${option.value}. `
        })
        .join("")
      : null


  const handleRemove = (_, item = lineItem) => {

    const lineItemIds = [];

    const matchingLineItems = cart.lines
      .filter(line => {
        const itemComponentOf = item.attributes?.find(attr => attr.key === "_componentOf")?.value;
        const checkoutComponentOf = line.attributes?.find(attr => attr.key === "_componentOf")?.value;
        return (itemComponentOf || checkoutComponentOf) !== undefined && itemComponentOf === checkoutComponentOf
      });

    const matchingLineItemIds = matchingLineItems?.map(line => line.id);

    if (matchingLineItemIds?.length > 0) {
      lineItemIds.push(...matchingLineItemIds);
    } else {
      lineItemIds.push(item.id)
    }

    if (item.warrantyDetails) {
      lineItemIds.push(item.warrantyDetails.id);
    }

    removeLineItems(cart.id, lineItemIds)

    if (matchingLineItems?.length > 0) {
      matchingLineItems.map(matchingLineItem => {
        trackRemoveFromCart([{
          ...matchingLineItem,
          quantity: '0',
        }], matchingLineItem.attributes?.find(attr => attr.key === '_list')?.value)
      })
    } else {
      trackRemoveFromCart([{
        ...lineItem,
        quantity: '0',
      }], lineItem.attributes.find(attr => attr.key === '_list').value)
    }
  }

  const updateQuantity = useCallback(quantity => {
    if (quantity === 0) {
      handleRemove(null, lineItem);
    } else if (warrantyDetails && quantity < warrantyDetails.quantity) {
      updateLineItems(cart.id, [
        {
          id: lineItem.id,
          quantity,
          attributes: lineItem.attributes,
          merchandiseId: lineItem.merchandise.id,
        },
        {
          id: warrantyDetails.id,
          quantity
        }
      ])
    } else {
      quantity < lineItem.quantity
        ? trackRemoveFromCart([{
          ...lineItem,
          quantity
        }], lineItem.attributes.find(attr => attr.key === '_list').value || '')
        : trackAddToCart([{
          ...lineItem,
          quantity
        }], lineItem.attributes.find(attr => attr.key === '_list').value || '')

      updateLineItems(cart.id, [{
        id: lineItem.id,
        quantity,
        attributes: lineItem.attributes,
        merchandiseId: lineItem.merchandise.id,
      }]);
    }
  }, [lineItem])

  const checkoutCurrency = cart?.currencyCode || getRegionCurrency()
  const lineItemCompareAtPrice =
    compareAtAmountPerQuantity?.amount || amountPerQuantity?.amount

  let totalPrice = parseFloat(totalAmount.amount)
  let totalCompareAtPrice = (parseFloat(lineItemCompareAtPrice) * lineItem.quantity);

  const ariaLabel = `Line item: ${lineItem.merchandise.product.title}. Selected Options: ${selectedOptionsRaw} Quantity: ${lineItem.quantity}. ${totalCompareAtPrice > totalPrice ? `Discounted Price: ${totalPrice} dollars. Original Price: ${totalCompareAtPrice} dollars` : `Original Price: ${totalPrice} dollars`}`;
  return (
    <div className={"flex p-2.5 border-1 border-darker-gray rounded-xl gap-x-5 flex-nowrap relative"} role={'listitem'} aria-label={ariaLabel} tabIndex={0} aria-hidden={false}>
      <Link to={productLink} state={{ fromList: 'Shopping Cart' }} onClick={() => { toggleAllFunction(); trackSelectItem([{ ...lineItem, position: index }], 'Shopping Cart') }} className="min-w-[33.3%] max-w-[33.3%] flex">
        {variantImage}
      </Link>
      <div className="flex flex-col items-start flex-grow gap-y-2.5 py-2.5 pr-2.5">
        <div className="items-center w-full">
          <Link to={productLink} state={{ fromList: 'Shopping Cart' }} onClick={() => { toggleAllFunction(); trackSelectItem([{ ...lineItem, position: index }], 'Shopping Cart') }} className="subquote font-medium flex items-center gap-x-xsmall w-fit pr-5">
            <span>{lineItem.merchandise.product.title}</span>
            {isCashback && (
              <button onClick={() => setCashbackOpened(true)} className="cursor-pointer">
                <svg height="20px" width="20px" viewBox="0,0,256,256" xmlns="http://www.w3.org/2000/svg">
                  <g textAnchor="none" fontSize="none" fontWeight="none" fontFamily="none" strokeDashoffset="0" strokeDasharray="" strokeMiterlimit="10" strokeLinejoin="miter" strokeLinecap="butt" strokeWidth="1" stroke="none" fillRule="nonzero" fill="#000000">
                    <g transform="scale(5.12,5.12)">
                      <path d="M25,2c-12.6907,0 -23,10.3093 -23,23c0,12.69071 10.3093,23 23,23c12.69071,0 23,-10.30929 23,-23c0,-12.6907 -10.30929,-23 -23,-23zM25,4c11.60982,0 21,9.39018 21,21c0,11.60982 -9.39018,21 -21,21c-11.60982,0 -21,-9.39018 -21,-21c0,-11.60982 9.39018,-21 21,-21zM25,11c-1.65685,0 -3,1.34315 -3,3c0,1.65685 1.34315,3 3,3c1.65685,0 3,-1.34315 3,-3c0,-1.65685 -1.34315,-3 -3,-3zM21,21v2h1h1v13h-1h-1v2h1h1h4h1h1v-2h-1h-1v-15h-1h-4z"></path>
                    </g>
                  </g>
                </svg>
              </button>
            )}
          </Link>
          {isPreOrBackOrder && (
            <span className="px-2 py-1 text-xs leading-snug tracking-wide text-white rounded sm:ml-3 bg-soft-black">
              {backOrderText}
            </span>
          )}
        </div>
        {isCashback && <div className="text-sm flex flex-wrap flex-col">
          {
            merchandise && merchandise.selectedOptions
              ? merchandise.selectedOptions
                .filter(({ value }) => value !== "Default Title")
                .map((option, index) => {
                  return option.value;
                })
                .join('')
              : null
          }
        </div>}
        {selectedOptions && !isCashback && <div className="text-sm flex flex-wrap flex-col">{selectedOptions}</div>}
        <div className="flex justify-between w-full items-end gap-1">
          {!isCashback && <QuantityInput
            currentQuantity={lineItem.quantity}
            onUpdate={updateQuantity}
            sku={lineItem.merchandise.sku}
            className="!ml-0 h-8 !p-0"
          />}
          {merchandise && !isCashback && (
            totalCompareAtPrice > totalPrice ? (
              <div className="flex flex-col gap-x-2.5">
                <p className="text-safety-green antialiased font-medium text-lg">
                  {getPrice(totalPrice)}
                </p>
                <p className="text-darker-gray line-through decoration-black decoration-1 antialiased font-medium text-right text-xs">
                  {getPrice(totalCompareAtPrice)}
                </p>
              </div>
            ) : (
              <p className="text-safety-green antialiased font-medium text-lg">
                {getPrice(totalPrice)}
              </p>
            )
          )
          }
        </div>
      </div>
      <button
        onClick={handleRemove}
        className="disabled:opacity-50 absolute top-5 right-5"
        disabled={loading}
        aria-label="Remove Item"
      >
        <Close className={'w-3 !stroke-[#FE4A25]'} />
      </button>
      {warrantyDetails && (
        <WarrantyDetails
          lineItem={warrantyDetails}
          checkoutCurrency={checkoutCurrency}
          productUnits={lineItem.quantity}
          onRemove={handleRemove}
          onUpdateQuantity={(quantity) => {
            updateLineItems(cart.id, [{
              ...warrantyDetails,
              quantity,
              attributes: lineItem.attributes,
            }])
          }}
        />
      )}
    </div>
  )
}

export default LineItem
