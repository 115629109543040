import { getRegionCurrency } from "@utils/region"

function formatPrice({ price = 0, currency = getRegionCurrency(), minimumFractionDigits = 0, parse = true }) {
  const appendCurrency = currency !== 'USD';
  const result = Intl.NumberFormat("en-US", {
    currency: currency,
    minimumFractionDigits,
    currencyDisplay: "narrowSymbol",
    trailingZeroDisplay: "auto",
    style: "currency",
  }).format(parse ? parseFloat(price) : price)
  

  if (appendCurrency) {
    return `${result} ${currency}`
  }

  return result
}

function formatPriceWithDecimals(price) {
  const number = +price
  
  if (Number.isInteger(number)) {
    return number.toString(); // No decimal places, return as is
  } else {
    return number.toFixed(2); // Format with two decimal places
  }
}

function getPricesFromPresentmentNodes(edges) {
  return {
    price: edges?.map(edge => edge.price),
    compareAtPrice: edges?.map(edge => edge.compareAtPrice)
  }
}

function getPricesFromContextualPricing(edges) {
  const currency = getRegionCurrency();

  return edges?.find(edge => edge.currency === currency)
}

function getPriceFromVariantPresentmentPrices(
  price = null,
  fallbackPrice = null,
) {
  return price ? parseFloat(price) : fallbackPrice
}

function getPriceFromAccumulatedPresentmentPrices(
  price = null,
  fallbackPrice = null,
  quantity = 1,
  crossSellProducts = [],
) {
  const crossSellProductsPrice = crossSellProducts?.map(crossSell => currencyOptionPrice(crossSell?.product) * crossSell?.quantity).reduce((x, y) => x + y, 0);
  const formattedPrice = (price * quantity) + crossSellProductsPrice;
  const fallback = quantity > 1 ? (fallbackPrice * quantity) + crossSellProductsPrice : fallbackPrice

  return formattedPrice ? parseFloat(formattedPrice) : fallback
}

function prices(productVariant) {
  return getPricesFromContextualPricing(
    productVariant?.prices
  )
}

function currencyOptionPrice(currentVariant, quantity, crossSellProducts) {
  if (quantity && crossSellProducts) {
    return getPriceFromAccumulatedPresentmentPrices(
      prices(currentVariant)?.price,
      currentVariant?.price,
      quantity,
      crossSellProducts,
    )
  }

  return getPriceFromVariantPresentmentPrices(
    prices(currentVariant)?.price,
    currentVariant?.price
  )
}

function currencyOptionCompareAtPrice(currentVariant) {
  return getPriceFromVariantPresentmentPrices(
    prices(currentVariant)?.compareAtPrice,
    null
  )
}

function getPrice(currentVariant, raw = false, discountAmountFromCart = 0) {
  if (raw) return currencyOptionPrice(currentVariant) - discountAmountFromCart

  return formatPrice({
    price: currencyOptionPrice(currentVariant) - discountAmountFromCart,
    currency: getRegionCurrency(),
    minimumFractionDigits: 0,
  })
}

function getAccumulatedPrice(currentVariant, quantity, crossSellProducts, raw = false, discountAmountFromCart = 0) {
  if (raw) {
    return currencyOptionPrice(currentVariant, quantity, crossSellProducts)
  }
  
  return formatPrice({
    price: currencyOptionPrice(currentVariant, quantity, crossSellProducts) - discountAmountFromCart,
    currency: getRegionCurrency(),
    minimumFractionDigits: 0,
  })
}

function getCompareAtPrice(currentVariant, raw = false, discountAmountFromCart = 0, discountAmountFromCode) {
  if (raw) return currencyOptionCompareAtPrice(currentVariant) - discountAmountFromCart

  return currencyOptionCompareAtPrice(currentVariant) 
    && parseFloat(currencyOptionCompareAtPrice(currentVariant) - discountAmountFromCode) !== parseFloat(currencyOptionPrice(currentVariant))
    && formatPrice({
      price: currencyOptionCompareAtPrice(currentVariant) - discountAmountFromCart,
      currency: getRegionCurrency(),
      minimumFractionDigits: 0,
    })
}

function discountPercentage(currentVariant) {
  return 100 - (currencyOptionPrice(currentVariant) / currencyOptionCompareAtPrice(currentVariant)) * 100
}

function discountValue(currentVariant, minVariantPrice) {
  return formatPrice({
    price: currencyOptionCompareAtPrice(currentVariant) - currencyOptionPrice(currentVariant),
    currency: getRegionCurrency() || minVariantPrice.currencyCode,
  })
}

function getDiscountDisplay(discountDisplayPercentage, currentVariant, minVariantPrice) {
  return discountDisplayPercentage === 'true' 
    ? Math.round(discountPercentage(currentVariant).toFixed(2)) + '%' 
    : discountValue(currentVariant, minVariantPrice)
}

function formattedPrice(price, variants) {
  return formatPrice({
    price,
    currency:
      getRegionCurrency() || variants[0].priceRange?.minVariantPrice,
    minimumFractionDigits: Number.isInteger(price) ? 0 : 2,
  })
}

export {
  getPrice, getDiscountDisplay, getCompareAtPrice, formatPrice,
  getPricesFromPresentmentNodes, getPriceFromVariantPresentmentPrices,
  prices, currencyOptionPrice, currencyOptionCompareAtPrice, getAccumulatedPrice,
  formatPriceWithDecimals, getPricesFromContextualPricing, formattedPrice
}