import React from "react"
import CartToggle from "@components/cart/cart-toggle"
import HamburgerMenu from "@svgs/hamburger-menu"
import Logo from "@components/logo"

const MobileHeader = ({ onHamburgerClick }) => {
  return (
    <div className="rounded-xl grid grid-cols-12 my-small max-xs:mt-[95px] mt-20 mx-large overflow-y-hidden md:hidden">
      <div className="col-span-12 bg-white grid grid-cols-12 py-4 px-5 subquote z-[51]">
        <div className="col-span-3 flex justify-start items-center">
          <HamburgerMenu onClick={onHamburgerClick} />
        </div>
        <Logo className="flex h-5 col-span-6 justify-center" />
        <div className="col-span-3 flex justify-end items-center">
          <CartToggle />
        </div>
      </div>
    </div>
  )
}
export default MobileHeader