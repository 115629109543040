import React from "react"
import { Buffer } from "buffer"
import getValue from "get-value"
import { getRegionHandle, getRegionShopUrl, isVisibleIn } from "@utils/region"
import { isEqual, omit } from "lodash"
import { currencyOptionCompareAtPrice, currencyOptionPrice } from "./products/pricing"
import { getUriFromDocument } from "./routing"
import { getImageUrl } from "./image"
import { trackViewItem } from "@src/services/analytics"

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w\-]+/g, "") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -
    .replace(/^-+/, "") // Trim - from start of text
    .replace(/-+$/, "") // Trim - from end of text
}

function truncateString(str, num) {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str
  }
  // Return str truncated with '...' concatenated to the end of str.
  return str.slice(0, num) + "..."
}

function htmlifyText(text) {
  return <span dangerouslySetInnerHTML={{ __html: text }}></span>
}

function variantIdFromShopifyId(id) {
  if(id) {
    const parts = id.split('/');
    return parts[parts.length - 1];
  }
}

function shopifyIdToBase64(id) {
  const base64 = Buffer.from(id).toString('base64');

  return base64
}

function ascSizeSorter(valA, valB) {
  if (!valA || !valB) return 0
  const sortMap = ['XS', 'S', 'Small', 'M', 'Medium', 'I', 'L', 'Large', 'S / M', 'I / L', 'XL', 'Extra Large']
  const indexA = sortMap.indexOf(valA)
  const indexB = sortMap.indexOf(valB)
  return indexA - indexB;
}

// TODO: Remove after images migration
function overrideOptions(opt) {
  const overrideValues = {
    "Thats My Jam": "That's My Jam",
    "Gold N Delicious": "Gold N' Delicious",
    "Small": "S",
    "Medium": "M",
    "Large": "L",
  }

  return overrideValues[opt] ? overrideValues[opt] : opt
}

function getOptionTitle(variant) {
  const { selectedOptions } = variant
  const sizeMap = {
    XS: "Extra Small",
    S: "Small",
    M: "Medium",
    I: "Intermediate",
    L: "Large",
    XL: "Extra Large",
  }
  const selectedOption = selectedOptions?.find(option => option.name === "Size")
  const optionValue = getValue(selectedOption, "value")

  return getValue(sizeMap, `${optionValue}`)
}

function getInitialVariant(variants, searchParams = null) {
  let variant;
  const localWindow = typeof window !== "undefined" && window
  const params = new URLSearchParams(searchParams || localWindow?.location?.search)
  const variantId = params.get("variantId") || params.get('variant')
  if (variantId) {
    variant = variants
    .find(_variant => 
      _variant.shopifyId === variantId && 
      isVisibleIn(_variant.visibilityRegions) === true && !_variant.hide)
  } else {
    variant = variants
      ?.find(_variant => _variant.isDefaultVariant && isVisibleIn(_variant.visibilityRegions) === true) || variants
      ?.find(_variant => 
        isVisibleIn(_variant.visibilityRegions) === true && !_variant.hide && _variant.availableForSale)
      || variants?.[0]
  }
  return variant
}

function extractSizeValue(variant) {
    const sizeOption = variant?.selectedOptions?.find(opt => opt.name === 'Size');
    return sizeOption ? getValue(sizeOption, 'value') : null;
};

function findInitialVariant(variants, isCrossSell, hasFeaturedVariants = false) {
  const byOptionsAndPrice = (varA, varB) => {
    const sizeAOpt = extractSizeValue(varA);
    const sizeBOpt = extractSizeValue(varB);
    const sizeSortValue = (!sizeAOpt || !sizeBOpt) ? 0 : sizeBOpt.localeCompare(sizeAOpt);

    const priceAOpt = getValue(varA, 'price');
    const priceBOpt = getValue(varB, 'price');
    const priceSortValue = (!priceAOpt || !priceBOpt) ? 0 : priceAOpt - priceBOpt

    return sizeSortValue + priceSortValue;
  }

  let variant;
  const localWindow = typeof window !== "undefined" && window
  const params = new URLSearchParams(localWindow?.location?.search)
  const variantId = params.get("variantId") || params.get("variant")
  
  if (!isCrossSell && variantId) {
    variant = variants
      ?.find(_variant => 
        _variant?.shopifyId === variantId && 
        isVisibleIn(_variant.visibilityRegions) === true && !_variant.hide ) || variants?.[0]
  } else if (hasFeaturedVariants) {
    variant = variants
      ?.filter(_variant => _variant.hide !== true && _variant.availableForSale && isVisibleIn(_variant.visibilityRegions) === true)
      ?.find(_variant => isVisibleIn(_variant.visibilityRegions) === true) || variants?.[0]
  } else {
    const filteredVariants = variants
      ?.filter(_variant => _variant.hide !== true && _variant.availableForSale && isVisibleIn(_variant.visibilityRegions) === true)
      ?.sort(byOptionsAndPrice)
      
    variant = filteredVariants?.find(_variant => _variant.isDefaultVariant) 
      || filteredVariants?.[0]
      || variants?.[0]
  }
  return variant
}

function filteredAndSortedOptions(_options, variants) {
  if (!_options) return _options;
  const options = JSON.parse(JSON.stringify(_options));

  options?.forEach(option => {
    const _values = []

    option.values = option.values.filter(value => {
      variants
        ?.filter(variant => !variant?.hide)
        ?.forEach(_variant => {
          return _variant?.selectedOptions?.forEach(_selectedOption => _values.push(_selectedOption.value))
        })

      return _values.indexOf(value) >= 0
    })
  })

  return (
    options?.map(({ name, values }, originalIndex) => {
      return { name, values, originalIndex }
    })
      .sort(({ name }, { name: nameB }) => name.localeCompare(nameB))
  )
}

function filterVariantsByRegion(variants, productEntry) {
  variants?.forEach(variant => {
    const productVariant = productEntry.variants.find((_var) =>
      _var.shopifyId == variantIdFromShopifyId(variant.shopifyId) || _var?.shopifyId == variant?.shopifyIdBase64Encoded
    );

    // Assign all Shopify info to Sanity productEntry, don't overwrite existing info
    productVariant && Object.entries(variant).forEach(([key, value]) =>
      !productVariant[key] ? productVariant[key] = value : ''
    )
  })

  return variants
}

function getSelectedDisplayValue(name, selectedValueValue, productOptions) {
  const currentOptionName = productOptions
    ?.find(({optionName}) => optionName?.toLowerCase() === name.toLowerCase())?.optionValues
    .find(({originalValueName}) => originalValueName?.toLowerCase() === selectedValueValue?.toLowerCase())
  return currentOptionName?.customDisplayedValueName
}

const setQueryStringParameter = (name, value) => {
  const localWindow = typeof window !== "undefined" && window

  if (!localWindow) return

  const params = new URLSearchParams(localWindow?.location?.search)

  params.set(name, value)
  localWindow?.history.replaceState(
    {},
    "",
    decodeURIComponent(`${window.location.pathname}?${params}`)
  )
}

function findMatch(name, value, variants, currentVariant) {
  const otherOptions = currentVariant?.selectedOptions?.filter(k => k.name !== name)

  const allMatch = variants
    .filter(variant => // Get all variants with same Option Value
      variant.selectedOptions?.find(option => 
        option.name === name && option.value === value
      )
    )

  const match = allMatch.find(({ selectedOptions }) => {
    const optionsToCompare = selectedOptions
      .filter(option => option.name !== name)
      .map(option => omit(option, ['_key']))
    const _otherOptions = otherOptions?.map(option => omit(option, ['_key']))

    return isEqual(_otherOptions, optionsToCompare)
  })

  return match
}

const checkStatus = (name, value, variants, currentVariant) => {
  const match = findMatch(name, value, variants, currentVariant)

  let isDisabled, isUnavailable, isOnSale, isAvailableWithPrime
  isDisabled = isUnavailable = isOnSale = isAvailableWithPrime = false

  if (match === undefined) {
    return ({
      isDisabled: true,
      isUnavailable: true,
      isOnSale,
      isAvailableWithPrime,
    })
  }
  const price = parseFloat(currencyOptionPrice(match))
  const compareAtPrice = parseFloat(currencyOptionCompareAtPrice(match))

  if (name === "Title" || match.hide) { isDisabled = true }
  if (name !== "Title" && !match?.availableForSale || (!match?.isAvailableInRegion && !isVisibleIn(match?.visibilityRegions))) { isUnavailable = true }
  if (name !== "Title" && compareAtPrice && compareAtPrice !== price) { isOnSale = true }
  if (match?.isAvailableWithPrime) { isAvailableWithPrime = match?.isAvailableWithPrime}

  return ({ isDisabled, isUnavailable, isOnSale, isAvailableWithPrime })
};

function checkOptionChecked(checkName, checkValue, currentVariant) {
  const matchingOption = currentVariant?.selectedOptions?.find(
    ({ name, value }) => name === checkName && value === checkValue
  )

  return matchingOption !== undefined
}

function useIsSsr() {
  const [isSsr, setIsSsr] = React.useState(true);

  React.useEffect(() => {
    setIsSsr(false);
  }, []);

  return isSsr;
}

function trackTripleWhaleAddToCart(ids) {
  if (typeof window.TriplePixel !== 'undefined') {
    if (typeof ids === 'object') {
      ids.forEach(id => {
        window.TriplePixel('AddToCart', {item: `${id}`, q: 1});
      })
    } else {
      window.TriplePixel('AddToCart', {item: `${ids}`, q: 1});
    }
  }
}

function formatProductOptions(options) {
  return options?.map(({ optionName, optionValues }) => ({ name: optionName, values: optionValues.map(({ originalValueName }) => originalValueName) }));
}

function isProductInRegion(product, variant) {
  return product.isAvailableInRegion && variant.isAvailableInRegion
}

function getProductOriginList(product) {
  return product?.productGroups?.length > 0
    ? getUriFromDocument(product?.productGroups[0])
    : ""
}

function trackProductView(product, variant, fromList) {
  const productFromList = fromList || getProductOriginList(product);

  trackViewItem(
    [
      {
        merchandise: {
          id: variant?.shopifyIdBase64Encoded,
          sku: variant?.sku,
          title: variant?.variantTitle,
          price: Number(variant?.price)?.toFixed(2),
          compareAtPrice: Number(variant?.compareAtPrice)?.toFixed(2),
          image: {
            src: getImageUrl(variant?.listingImage),
          },
          product: {
            id: product?.shopifyId,
            title: product?.shortTitle,
            category: product?.productGroups?.map(group => group.title),
            list: productFromList,
            onlineStoreUrl: getRegionShopUrl() + window?.location?.pathname,
          },
        },
        quantity: null,
      },
    ],
    productFromList
  )
}

function getLineItemsData(product, variant, quantity, crossSellProducts, fromList) {
  let lineItems = []
  let lineItemsData = []

  const productFromList = fromList || getProductOriginList(product);

  if (variant.variantComponents?.length > 0) {
    const componentId = Math.random()

    variant.variantComponents.forEach(variantComponent => {
      const { shopifyIdBase64Encoded, status } = variantComponent
      lineItemsData.push({
        merchandise: {
          id: shopifyIdBase64Encoded,
          sku: variantComponent.sku,
          title: variantComponent.variantTitle,
          price: Number(variantComponent.price)?.toFixed(2),
          compareAtPrice: Number(variantComponent.compareAtPrice)?.toFixed(2),
          image: {
            src: getImageUrl(variantComponent.listingImage),
          },
          product: {
            id: atob(product.shopifyId),
            title: product.shortTitle,
            category: product?.productGroups?.map(group => group.title),
            list: productFromList,
            onlineStoreUrl:
              getRegionShopUrl() + getUriFromDocument(product),
          },
        },
        quantity: 1,
      })

      lineItems.push({
        merchandiseId: shopifyIdBase64Encoded,
        attributes: {
          _preorder: status?.preorder ? "true" : "false",
          _backorder: status?.backorder ? "true" : "false",
          _region: getRegionHandle(),
          _componentOf: componentId.toString(36),
          _category: product?.productGroups
            ?.map(group => group.title)
            .join(", "),
          _list: productFromList,
        },
        quantity,
      })
    })
  } else {
    const hasStyluxReplacementForBaseProduct = crossSellProducts.some(
      upsellProduct => {
        return (
          upsellProduct.product.attributes._STYLUX_FULFILLMENT_REPLACEMENT ===
          "true"
        )
      }
    )
    if (!hasStyluxReplacementForBaseProduct) {
      lineItemsData.push({
        merchandise: {
          id: variant.shopifyIdBase64Encoded,
          sku: variant.sku,
          title: variant.variantTitle,
          price: Number(variant.price)?.toFixed(2),
          compareAtPrice: Number(variant.compareAtPrice)?.toFixed(2),
          image: {
            src: getImageUrl(variant?.listingImage),
          },
          product: {
            id: atob(product.shopifyId),
            title: product.shortTitle,
            category: product?.productGroups?.map(group => group.title),
            list: productFromList,
            onlineStoreUrl: getRegionShopUrl() + window.location.pathname,
          },
        },
        quantity,
      })

      lineItems.push({
        merchandiseId: variant.shopifyIdBase64Encoded,
        attributes: {
          _preorder: variant?.preorder ? "true" : "false",
          _backorder: variant?.backorder ? "true" : "false",
          _region: getRegionHandle(),
          _category: product?.productGroups
            ?.map(group => group.title)
            .join(", "),
          _list: productFromList,
        },
        quantity,
      })
    }
  }

  if (Object.keys(crossSellProducts).length !== 0) {
    crossSellProducts.forEach(
      ({ product: crossSellProduct, quantity }) => {
        trackTripleWhaleAddToCart(crossSellProduct.shopifyId)

        lineItemsData.push({
          merchandise: {
            id: crossSellProduct.shopifyId,
            sku: crossSellProduct.sku,
            title: crossSellProduct.variantTitle,
            price: Number(crossSellProduct.price)?.toFixed(2),
            compareAtPrice: Number(crossSellProduct.compareAtPrice)?.toFixed(2),
            image:
              crossSellProduct.variantImages &&
                crossSellProduct.variantImages.length
                ? {
                  src: getImageUrl(crossSellProduct.variantImages[0]),
                }
                : null,
            product: {
              id: crossSellProduct.product.yotpoProductId,
              title: crossSellProduct.product.title,
              category: crossSellProduct.product.category,
              onlineStoreUrl: crossSellProduct.product.onlineStoreUrl,
            },
          },
          quantity,
        })

        return lineItems.push({
          merchandiseId: crossSellProduct.shopifyIdBase64Encoded,
          attributes: {
            ...crossSellProduct.attributes,
          },
          quantity,
        })
      }
    )
  }

  return { lineItems, lineItemsData }
}

export {
  slugify, truncateString, htmlifyText, variantIdFromShopifyId, 
  ascSizeSorter, getInitialVariant, findInitialVariant, overrideOptions, 
  getOptionTitle, filteredAndSortedOptions,filterVariantsByRegion, 
  getSelectedDisplayValue, setQueryStringParameter, checkOptionChecked, 
  findMatch, checkStatus, useIsSsr, shopifyIdToBase64, trackTripleWhaleAddToCart, 
  formatProductOptions, isProductInRegion, getProductOriginList, trackProductView,
  getLineItemsData
}
