import { getAtcLabels } from "@src/utils/general";
import {
  getAccumulatedPrice,
  getCompareAtPrice,
  getDiscountDisplay,
  getPrice,
} from "@src/utils/products/pricing"

export const setCurrentVariant = (set, get, variant) => {
  const siteSettings = get().siteSettingsStore.siteSettings

  set(state => ({
    productStore: {
      ...state.productStore,
      currentVariant: {
        ...variant,
        watchlist: variant?.status?.watchlist,
        backorder: variant?.status?.backorder,
        preorder: variant?.status?.preorder,
        atcLabel: getAtcLabels(variant, siteSettings),
      },
    },
  }))
}

export const setAddedCrossSellProducts = (set, get, products) => {
  const crossSellProducts = get().productStore.addedCrossSellProducts;
  const variant = get().productStore.currentVariant;

  const newCrossSells = crossSellProducts.find(crossSell => products.map(product => product?.product?.shopifyId).includes(crossSell?.product?.shopifyId))
    ? crossSellProducts
    : [...crossSellProducts, ...products]

  set(state => ({
    productStore: {
      ...state.productStore,
      addedCrossSellProducts: newCrossSells
    },
  }))

  setAccumulatedPrice(set, get, variant);
}

export const removeAddedCrossSellProducts = (set, get, products) => {
  const crossSellProducts = get().productStore.addedCrossSellProducts;
  const variant = get().productStore.currentVariant;

  const newCrossSells = products?.length
    ? crossSellProducts.filter(crossSell =>
      !products?.map(product => product.shopifyId).includes(crossSell?.product?.product?.shopifyId)
    )
    : [];

  set(state => ({
    productStore: {
      ...state.productStore,
      addedCrossSellProducts: newCrossSells
    },
  }));

  setAccumulatedPrice(set, get, variant);
};

export const setPrices = (set, get, variant, discountAmountFromCode = 0) => {
  const price = getPrice(variant)
  const priceRaw = getPrice(variant, true)

  const compareAtPrice = getCompareAtPrice(variant, false, 0, discountAmountFromCode)
  const compareAtPriceRaw = getCompareAtPrice(variant, true, 0, discountAmountFromCode)

  set(state => ({
    productStore: {
      ...state.productStore,
      price: price,
      priceRaw: priceRaw,
      compareAtPrice,
      compareAtPriceRaw,
    },
  }))

  setAccumulatedPrice(set, get, variant);
}

export const setAccumulatedPrice = (set, get, variant) => {
  const quantity = get().productStore.quantity;
  const addedCrossSellProducts = get().productStore.addedCrossSellProducts;

  const accumulatedPrice = getAccumulatedPrice(variant, quantity, addedCrossSellProducts, false);

  const accumulatedPriceRaw = getAccumulatedPrice(variant, quantity, addedCrossSellProducts, true);

  set(state => ({
    productStore: {
      ...state.productStore,
      accumulatedPrice,
      accumulatedPriceRaw,
    },
  }))
}

export const setDiscountDisplay = (set, variant, product) => {
  const { discountDisplayPercentage } = product
  const minVariantPrice = product.priceFrom

  const discountDisplay = getDiscountDisplay(
    discountDisplayPercentage,
    variant,
    minVariantPrice
  )

  set(state => ({
    productStore: {
      ...state.productStore,
      discountDisplay,
    },
  }))
}

export const setCouponDiscountDisplay = (
  set,
  get,
  productId,
  sanityDiscountCodes
) => {
  const cart = get().cartStore.cart
  const price = get().productStore.priceRaw
  const variant = get().productStore.currentVariant

  const appliedDiscount = sanityDiscountCodes?.find(
    discount =>
      cart?.discountCodes?.find(
        cartDiscount =>
          discount?.code?.toLowerCase() === cartDiscount?.code?.toLowerCase()
      ) && discount?.products?.find(prod => prod._id === productId)
  )

  const discountAmountFromCode =
    appliedDiscount?.discountType === "fixed"
      ? appliedDiscount?.fixedAmount
      : price * (appliedDiscount?.percentageAmount / 100)

  set(state => ({
    productStore: {
      ...state.productStore,
      couponDiscountDisplay: {
        amount: discountAmountFromCode || null,
        coupon: appliedDiscount || null,
      },
    },
  }))
  
  setPrices(set, get, variant, discountAmountFromCode || 0)
}

export const setQuantity = (set, get, quantity) => {

  const variant = get().productStore.currentVariant

  set(state => ({
    productStore: {
      ...state.productStore,
      quantity: quantity,
    },
  }))

  setPrices(set, get, variant)
}
