import React from "react"
import {
  addLineItemsToCart,
  calculateItemsRemoved,
  fetchBundleProducts,
  fetchCart,
  fetchOrCreateCart,
  processLineItems,
  removeLineItemsFromCart,
  setUpsells,
  updateDiscount,
  updateLineItemsInCart,
} from "@src/utils/cart";
import AlertDialog from "@src/components/alert";

export const initializeCart = async (set, get) => {
  await fetchOrCreateCart(set, get);
}

export const addLineItems = async (set, get, lines) => {
  set((state) => ({
    cartStore: {
      ...state.cartStore,
      loading: true,
    }
  }));
  const { siteSettingsStore: { setAlert }, cartStore: { cart } } = get();
  setAlert(<AlertDialog>Product was added to cart</AlertDialog>);

  const cartId = cart?.id;

  const processedLines = processLineItems(lines);

  try {
    const updatedCart = await addLineItemsToCart(cartId, processedLines);
    const cartWithAttributes = await fetchBundleProducts(set, updatedCart);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...cartWithAttributes,
        },
        loading: false,
      }
    }));

    await setUpsells(set, get);

    return updatedCart;
  } catch (error) {
    console.log("Error adding line items:", error);
    return null;
  }
};

export const removeLineItems = async (set, get, cartId, lineIds) => {
  const { lines } = get().cartStore.cart;

  set((state) => ({
    cartStore: {
      ...state.cartStore,
      loading: true,
    }
  }));

  try {
    const finalLineIdsToRemove = new Set(lineIds);

        lineIds.forEach((lineId) => {
      const lineItem = lines.find((line) => line.id === lineId);
      if (lineItem) {
        const attributesObj = Object.fromEntries(
          lineItem.attributes.map(attr => [attr.key, attr.value])
        );
        const bundleId = attributesObj["_bundleUniqueId"];
        if (bundleId) {
          lines.forEach((item) => {
            const itemAttrs = Object.fromEntries(
              item.attributes.map(attr => [attr.key, attr.value])
            );
            if (itemAttrs["_bundleUniqueId"] === bundleId) {
              finalLineIdsToRemove.add(item.id);
            }
          });
        }
      }
    });

    const removalIdsArray = [...finalLineIdsToRemove];

    if (lines.length !== removalIdsArray.length) {
      calculateItemsRemoved(set, get, removalIdsArray);
    }

    const updatedCart = await removeLineItemsFromCart(cartId, removalIdsArray);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
        loading: false,
      }
    }));

    await setUpsells(set, get);
    return updatedCart;
  } catch (error) {
    console.error("Error removing line items:", error);
    return null;
  }
};

export const updateLineItems = async (set, get, cartId, lines) => {
  try {
    const { lines: existingCartLines } = get().cartStore.cart;
    const bundleUpdates = {};

    lines.forEach((line) => {
      const existingLineItem = existingCartLines.find((item) => item.id === line.id);
      if (!existingLineItem) return;
      const attributesObj = Object.fromEntries(
        existingLineItem.attributes.map(attr => [attr.key, attr.value])
      );
      const bundleId = attributesObj["_bundleUniqueId"];
      if (bundleId) {
        bundleUpdates[bundleId] = line.quantity;
      }
    });

    const finalUpdates = [];

    existingCartLines.forEach((item) => {
      const itemAttrs = Object.fromEntries(
        item.attributes.map(attr => [attr.key, attr.value])
      );
      const bundleId = itemAttrs["_bundleUniqueId"];
      if (bundleId && bundleUpdates[bundleId] !== undefined) {

        let newQuantity = bundleUpdates[bundleId];

        if (itemAttrs["_freeGift"] === "true") {
          newQuantity = 1;
        }
        finalUpdates.push({
          id: item.id,
          quantity: newQuantity,
        });
      }
    });


    lines.forEach((line) => {
      const existingLineItem = existingCartLines.find((item) => item.id === line.id);
      if (!existingLineItem) return;
      const itemAttrs = Object.fromEntries(
        existingLineItem.attributes.map(attr => [attr.key, attr.value])
      );
      if (!itemAttrs["_bundleUniqueId"]) {
        finalUpdates.push(line);
      }
    });

    calculateItemsRemoved(set, get, null, finalUpdates);

    const updatedCart = await updateLineItemsInCart(cartId, finalUpdates);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
      }
    }));

    return updatedCart;
  } catch (error) {
    console.error("Error updating line items:", error);
    return null;
  }
};

export const addDiscount = async (set, get, discountCodes) => {
  const { cart } = get().cartStore;
  const cartId = cart?.id;

  try {
    const { updatedCart, userErrors } = await updateDiscount(cartId, discountCodes);

    if (userErrors) {
      console.error("Error adding discount code:", userErrors);
      set((state) => ({
        cartStore: {
          ...state.cartStore,
          cart: {
            ...state.cartStore.cart,
            ...userErrors,
          },
        }
      }));
    } else {
      set((state) => ({
        cartStore: {
          ...state.cartStore,
          cart: {
            ...state.cartStore.cart,
            ...updatedCart,
          },
        }
      }));
    }

    return { cart: updatedCart, userErrors };
  } catch (error) {
    console.error("Error adding discount code:", error);

    return null;
  }
};

export const removeDiscount = async (set, get) => {
  const { cart } = get().cartStore;
  const cartId = cart.id;

  try {
    const updatedCart = await updateDiscount(cartId);

    set((state) => ({
      cartStore: {
        ...state.cartStore,
        cart: {
          ...state.cartStore.cart,
          ...updatedCart,
        },
      }
    }));

    return updatedCart;
  } catch (error) {
    console.log("Error removing discount code:", error);

    return null;
  }
};

export const toggleDrawer = (set, override) => {
  set((state) => ({
    cartStore: {
      ...state.cartStore,
      isDrawerOpen: override !== null ? override : state.cartStore.isDrawerOpen,
    }
  }));
};

export const clearCart = async (set, get) => {
  const { id, lines } = get().cartStore.cart;

  try {
    const newCart = await updateLineItemsInCart(id, lines?.map(line => ({ id: line.id, quantity: 0 })));

    if (newCart) {
      set((state) => ({
        cartStore: {
          ...state.cartStore,
          cart: newCart,
          itemsRemoved: 0,
        },
      }));
    }
  } catch (error) {
    console.error("Cart could not be reinitialized:", error);
  }
}
