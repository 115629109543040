import getValue from "get-value"
import dotProp from "dot-prop"
import { getImageUrlFromImage } from "@utils/image"
import {getRegionCurrency, getRegionLinkHrefs, getRegionShopUrl} from "@utils/region"
import { findInitialVariant } from "./product"

export const generateSeoFromDocument = (
  document = {},
  extraConfig = {},
  previousSeoResult = {}
) => {
    const config = Object.assign({
        includeTitleSuffix: true
    }, extraConfig)
  const resultingData = Object.assign(previousSeoResult || {}, {
    language: 'en',
    languageAlternates: getRegionLinkHrefs(config?.canonical),
  })
  const fieldMap = [
    {
      key: "title",
      extraKeys: ["openGraph.title"],
      targetKeys: ["title", "openGraph.title"],
    },
    {
      key: "description",
      extraKeys: ["openGraph.description"],
      targetKeys: ["description", "summary", "openGraph.description"],
    },
    {
      key: "image",
      targetKeys: ["image", "mainImage", "openGraph.image"],
    },
  ]
  // const { overrideFields } = config
  fieldMap.forEach(item => {
    item.targetKeys.forEach(key => {
      let val = getValue(document, key)

      if (val && val !== "") {
        if (config.includeTitleSuffix && key === 'title') {
            val = `${val} - Diggs`
        }

        resultingData[item.key] = val

        if (item.extraKeys) {
          item.extraKeys.forEach(extraKey => {
            dotProp.set(resultingData, extraKey, val)
          })
        }
      }
    })
  })

  const imageData = getValue(resultingData, "image")

  if (imageData) {
    let imageUrl
    try {
      imageUrl = getImageUrlFromImage(imageData)
    } catch(e) {
      console.log("Failed: ", e);
    }
    dotProp.set(resultingData, "image", imageUrl)
    dotProp.set(resultingData, "openGraph.images", [
      {
        url: imageUrl,
        width: 1200,
        height: 630,
      },
    ])
  } else {
    dotProp.set(resultingData, "openGraph.image", {
      url: "https://cdn.sanity.io/images/yt8n50id/production-new/5628a136197087b65d195091d250f208b58ae4ad-2100x515.png?fit=max&w=1200&h=1200",
      width: 1200,
      height: 630,
    })
  }

  return resultingData
}

const productUrl = (product, variant) => {
  const url =  getRegionShopUrl() + "/collections/" + product?.productGroups[0]?.slug?.current + "/" + product?.slug?.current + "/";

  return product?.isBundle ? url : url + `?variantId=${variant?.shopifyId}`;
}

const variantAvailability = (product, variant) => {
  if (product?.isBundle) {
    return "https://schema.org/InStock"
  } else {
    if (variant?.status?.watchlist || !variant?.availableForSale) {
      return "https://schema.org/OutOfStock"
    } else if (variant?.status?.backorder) {
      return "https://schema.org/BackOrder"
    } else if (variant?.status?.preorder) {
      return "https://schema.org/PreOrder"
    } else {
      return "https://schema.org/InStock"
    }
  }
};

const bundleProductPrice = (product) => {
  const allPrices = product?.bundleProductsList.map(bundleProduct => findInitialVariant(bundleProduct.variants)?.price || bundleProduct.variants?.[0].price);

  const priceValue = allPrices.reduce((total, itemPrice) => total + itemPrice, 0)

  return Math.ceil((priceValue - (priceValue * +product?.discountAmount / 100)) * 100) / 100;
}

const variantImage = (product, variant) => product?.isBundle ? product?.mainImage || product?.bundleImages?.[0] : variant?.listingImage || variant?.variantImages?.[0];
const productPrice = (product, variant) => product?.isBundle ? bundleProductPrice(product) : variant?.price;

export const generateProductJsonLd = (product = {}, variant = {}) => {
  return {
    "@context": "https://schema.org",
    "@type": "Product",
    brand: {
      "@type": "Brand",
      name: "Diggs"
    },
    name: product?.title,
    description: product?.summary ? product?.summary : product?.isBundle ? "Bundle Product" : 'Product',
    sku: variant?.sku,
    image: variantImage(product, variant) ? getImageUrlFromImage(variantImage(product, variant)) : '',
    aggregateRating: {
      "@type": "AggregateRating",
      ratingValue: product?.averageRating || '4.9',
      ratingCount: product?.totalReviews || '100',
    },
    offers: {
      "@type": "Offer",
      price: productPrice(product, variant),
      priceCurrency: getRegionCurrency(),
      // priceValidUntil: '',
      itemCondition: 'https://schema.org/NewCondition',
      availability: variantAvailability(product, variant),
      url: productUrl(product, variant),
      seller: {
        "@type": "Organization",
        name: "Diggs"
      }
    }
  }
}

export const generateReviewJsonLd = (product = {}, variant = {}) => {
  return {
    "@context": "http://schema.org",
    "@type": "Review",
    name: "Review of the Diggs Products",
    description: "This is a review of the Diggs Products. We tested the products and found them to be of high quality and value.",
    itemReviewed: {
      "@type": "Product",
      name: product?.title,
      description: product?.summary || product?.isBundle ? "Bundle Product" : 'Product',
      sku: product?.sku,
      image: variantImage(product, variant) ? getImageUrlFromImage(variantImage(product, variant)) : '',
      brand: {
        "@type": "Brand",
        name: "Diggs"
      },
      offers: {
        "@type": "Offer",
        price: productPrice(product, variant),
        priceCurrency: getRegionCurrency(),
        // priceValidUntil: '',
        itemCondition: 'https://schema.org/NewCondition',
        availability: variantAvailability(product, variant),
        url: productUrl(product, variant),
        seller: {
          "@type": "Organization",
          name: "Diggs"
        }
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: product?.averageRating || '4.9',
        ratingCount: product?.totalReviews || '100',
      },
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: 5
        },
        author: {
          "@type": "Person",
          name: "Anonymous"
        },
        datePublished: "2023-03-16",
        reviewBody: "This is a great product!"
      }
    },
    author: {
      "@type": "Person",
      name: "Anonymous"
    },
    publisher: {
      "@type": "Organization",
      name: "Diggs"
    },
    datePublished: "2023-03-16",
    reviewRating: {
      "@type": "Rating",
      ratingValue: 5,
      bestRating: 5,
      worstRating: 1
    }
  }
}

export const generateThumbnailJsonLd = () => {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": "https://www.diggs.pet/",
    "thumbnailUrl": "https://cdn.sanity.io/images/yt8n50id/production-new/5628a136197087b65d195091d250f208b58ae4ad-2100x515.png?fit=max&w=1200&h=1200"
  }
}

export function toPlainText(blocks) {
  if (!blocks) {
    return ""
  }
  return blocks
    .map(block => {
      if (block._type !== "block" || !block.children) {
        return ""
      }
      return block.children.map(child => child.text).join("")
    })
    .join("\n\n")
}
